/*** Project mixins ***/

/* mixins START */

// list center
@mixin list-center() {

	ul, ol {
		padding-left: 0;

		li:before {
			display: inline;
			margin-left: 0;
		}
	}
}

// list right
@mixin list-right() {

	ul, ol {
		padding-left: 0;

		li:before {
			display: inline;
			margin-left: 0;
		}
	}
}

// view height fix
@mixin vh-fix() {

	// desktop
	@media (min-width: $screen-lg-min) {
		height: 100vh;
	}

	// tablet horizontal
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		height: 740px;
	}

	// tablet vertical
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		height: 1000px;
	}

	// mobile horizontal
	@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
		padding-top: 5%;
		padding-bottom: 5%;
	}
 
	// mobile vertical
	@media (max-width: ($screen-xs-min - 1)) {
		padding-top: 5%;
		padding-bottom: 5%;
	}

}

// view height fix line-height - optional
@mixin vh-fix-line-height() {

	// desktop
	@media (min-width: $screen-lg-min) {
		line-height: 100vh;
	}

	// tablet horizontal
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		line-height: 740px;
	}

	// tablet vertical
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		line-height: 1000px;
	}

	// mobile horizontal
	@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
		line-height: 300px;
	}
 
	// mobile vertical
	@media (max-width: ($screen-xs-min - 1)) {
		line-height: 460px;
	}

}

/* mixins END */

