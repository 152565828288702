/*** Project variables defaults ***/

/* variables START */

/* ---- setup DEFAULTS START */

// border radius
$project-default-border-radius:				0px;

// color

	// basic colors
	$color-black:							black;
	$color-blue:							blue;
	$color-dark-blue:					#11192d;
	$color-brown:							brown;
	$color-green:							#00c4db;
	$color-dark-green:				darkgreen;
	$color-gray:							#f8f8f8;
	$color-light-gray:				#e7e8ea;
	$color-dark-gray:					darkgray;
	$color-red:								red;
	$color-dark-red:					darkred;
	$color-orange:						orange;
	$color-dark-orange:				darkorange;
	$color-yellow:						yellow;
	$color-white:							white;

	// social colors
//	$color-bandcamp:						#4E9BAC;
//	$color-delicious:						#248CFF;
//	$color-facebook:						#3B5998;
//	$color-ficly:							#B51C2C;
//	$color-flickr:							#FF0084;
//	$color-foursquare:						#0072B1;
//	$color-googleplus:						#DD4B39;
//	$color-github:							#4183C4;
//	$color-instagram:						#517FA4;
//	$color-kickstarter:						#76CC1E;
//	$color-lanyrd:							#152E51;
//	$color-lastfm:							#d51007;
//	$color-linkedin:						#007BB6;
//	$color-photodrop:						#CDB6E3;
//	$color-pinterest:						#CB2027;
//	$color-rdio:							#0088DA;
//	$color-quora:							#A82400;
//	$color-snapchat:						#FFFC00;
//	$color-soundcloud:						#FF6600;
//	$color-tumblr:							#32506D;
//	$color-twitter: 						#00ACED;
//	$color-vimeo:							#AAD450;
//	$color-vine:							#00BF8F;
//	$color-vk:								#45668E;
//	$color-youtube:							#BB0000;

	// default
	$project-default-color:					$color-black;

// font family
$project-default-font-family-WEBSAFE-1:		Arial, Helvetica, sans-serif;

$project-default-font-family-1-REGULAR:		'ProximaNova-Regular_1', $project-default-font-family-WEBSAFE-1;
$project-default-font-family-1-SEMIBOLD:	'ProximaNova-Semibold_1', $project-default-font-family-WEBSAFE-1;
$project-default-font-family-BOLD:				'ProximaNova-Bold_1', $project-default-font-family-WEBSAFE-1;
$project-default-font-family-1-PT-BOLD:		'PT_Serif-Web-Bold', $project-default-font-family-WEBSAFE-1;

$project-default-font-family:					$project-default-font-family-1-REGULAR;

// font size
$project-default-font-size:						18px;

// height
$project-default-button-height:				45px;

// line height
$project-default-line-height-diff:		8px;
$project-default-line-height:					$project-default-font-size + $project-default-line-height-diff;

// margin
$project-default-margin:							0px 0px $project-default-line-height 0px;
$project-default-margin-bottom:				66px;

// opacity
$project-default-opacity-HOVER:				0.5;

// transitions
@mixin project-default-transition()			{ @include css3-transition(all, 0.5s, ease); } // USE THIS: @include project-default-transition();


/* ---- setup DEFAULTS END */



/* ---- create a LIST FROM DEAFULT COLORS START */

// list of colors for class generators
$colors: (

	// basic colors
	color-black:		$color-black,
	color-blue:			$color-blue,
	color-dark-blue:	$color-dark-blue,
	color-brown:		$color-brown,
	color-green:		$color-green,
	color-dark-green:	$color-dark-green,
	color-gray:			$color-gray,
	color-light-gray:	$color-light-gray,
	color-dark-gray:	$color-dark-gray,
	color-red:			$color-red,
	color-dark-red:		$color-dark-red,
	color-orange:		$color-orange,
	color-dark-orange:	$color-dark-orange,
	color-yellow:		$color-yellow,
	color-white:		$color-white,

	// social colors
//	color-bandcamp:		$color-bandcamp,
//	color-delicious:	$color-delicious,
//	color-facebook:		$color-facebook,
//	color-ficly:		$color-ficly,
//	color-flickr:		$color-flickr,
//	color-foursquare:	$color-foursquare,
//	color-googleplus:	$color-googleplus,
//	color-github:		$color-github,
//	color-instagram:	$color-instagram,
//	color-kickstarter:	$color-kickstarter,
//	color-lanyrd:		$color-lanyrd,
//	color-lastfm:		$color-lastfm,
//	color-linkedin:		$color-linkedin,
//	color-photodrop:	$color-photodrop,
//	color-pinterest:	$color-pinterest,
//	color-rdio:			$color-rdio,
//	color-quora:		$color-quora,
//	color-snapchat:		$color-snapchat,
//	color-soundcloud:	$color-soundcloud,
//	color-tumblr:		$color-tumblr,
//	color-twitter: 		$color-twitter,
//	color-vimeo:		$color-vimeo,
//	color-vine:			$color-vine,
//	color-vk:			$color-vk,
//	color-youtube:		$color-youtube
);

/* ---- create a LIST FROM DEAFULT COLORS END */
