/*** Project variables ***/

/* variables START */

@import "project-variables-defaults";

/* ---- create a LIST FROM DEAFULT COLORS START */

/* ---- setup loader START */

// layer
$project-loader-layer-color:										$color-white;
$project-loader-layer-speed:										0.25s;
$project-loader-layer-delay:										0.25s;

// circles
$project-loader-item-1-color:										$color-blue;
$project-loader-item-2-color:										$color-red;
$project-loader-item-3-color:										$color-green;
$project-loader-item-distance:									5px;
$project-loader-item-size:											20px;
$project-loader-item-speed:											1.5s;


/* ---- setup loader END */



/* ---- setup TYPOGRAPHY START */

// link
$project-link-color:												$color-dark-blue;
$project-link-color-HOVER:									$color-white;
$project-link-font-family:									$project-default-font-family;
$project-link-opacity-HOVER:								$project-default-opacity-HOVER;

// headings

	// h1
	$project-h1-color:												$color-blue;
	$project-h1-font-family:										$project-default-font-family;
	$project-h1-font-size:											$project-default-font-size + 10;
	$project-h1-line-height:										$project-h1-font-size + $project-default-line-height-diff;
	$project-h1-margin:												$project-default-margin-bottom;

	// h2
	$project-h2-color:												$color-blue;
	$project-h2-font-family:										$project-default-font-family;
	$project-h2-font-size:											$project-default-font-size + 8;
	$project-h2-line-height:										$project-h2-font-size + $project-default-line-height-diff;
	$project-h2-margin:												$project-default-margin-bottom;

	// h3
	$project-h3-color:												$color-blue;
	$project-h3-font-family:										$project-default-font-family;
	$project-h3-font-size:											$project-default-font-size + 6;
	$project-h3-line-height:										$project-h3-font-size + $project-default-line-height-diff;
	$project-h3-margin:												$project-default-margin-bottom;

	// h4
	$project-h4-color:												$color-blue;
	$project-h4-font-family:										$project-default-font-family;
	$project-h4-font-size:											$project-default-font-size + 4;
	$project-h4-line-height:										$project-h4-font-size + $project-default-line-height-diff;
	$project-h4-margin:												$project-default-margin-bottom;

	// h5
	$project-h5-color:												$color-blue;
	$project-h5-font-family:										$project-default-font-family;
	$project-h5-font-size:											$project-default-font-size + 2;
	$project-h5-line-height:										$project-h5-font-size + $project-default-line-height-diff;
	$project-h5-margin:												$project-default-margin-bottom;

	// h6
	$project-h6-color:												$color-blue;
	$project-h6-font-family:										$project-default-font-family;
	$project-h6-font-size:											$project-default-font-size;
	$project-h6-line-height:										$project-h6-font-size + $project-default-line-height-diff;
	$project-h6-margin:												$project-default-margin-bottom;

// paragraph
$project-p-color:													$project-default-color;
$project-p-font-family:												$project-default-font-family;
$project-p-font-size:												$project-default-font-size;
$project-p-line-height:												$project-default-line-height;
$project-p-margin:													$project-default-margin-bottom;

// list
$project-list-color:												$project-default-color;
$project-list-font-family:											$project-default-font-family;
$project-list-font-size:											$project-default-font-size - 2;
$project-list-line-height:											$project-default-line-height;
$project-list-margin:												$project-default-margin-bottom;
$project-list-padding:												40px;
$project-list-padding-list-style-type:								10px;

	// ul style type - remove this if you don't need skinned "list-style-type"
	$project-ul-style-type-color:									$project-default-color;
	$project-ul-style-type-content:									'\2022'; // '•'
	$project-ul-style-type-font-family:								$project-default-font-family;
	$project-ul-style-type-font-size:								$project-default-font-size;

	// ol style type - remove this if you don't need skinned "list-style-type"
	$project-ol-style-type-color:									$project-default-color;
	$project-ol-style-type-content:									counter(li, decimal)'.'; // '1.'
	$project-ol-style-type-font-family:								$project-default-font-family;
	$project-ol-style-type-font-size:								$project-default-font-size;

// definition list
$project-dl-margin:													$project-default-margin-bottom;

$project-dt-color:													$project-default-color;
$project-dt-font-family:											$project-default-font-family;
$project-dt-font-size:												$project-default-font-size;
$project-dt-line-height:											$project-default-line-height;

$project-dd-color:													$project-default-color;
$project-dd-font-family:											$project-default-font-family;
$project-dd-font-size:												$project-default-font-size;
$project-dd-line-height:											$project-default-line-height;

// blockquote
$project-blockquote-border-color:									$color-blue;
$project-blockquote-border-width:									0 0 0 5px;
$project-blockquote-color:											$project-default-color;
$project-blockquote-font-family:									$project-default-font-family;
$project-blockquote-font-size:										$project-default-font-size;
$project-blockquote-line-height:									$project-default-line-height;
$project-blockquote-margin:											$project-default-margin-bottom;
$project-blockquote-padding:										15px;

// strong
$project-strong-font-family:										$project-default-font-family;

// em
$project-em-font-family:											$project-default-font-family;

// em + strong
$project-em-strong-font-family:										$project-default-font-family;

// small
$project-small-font-size:											90%;

// highlight
$project-highlight-color:											$color-orange;
$project-highlight-font-family:										inherit;
$project-highlight-font-size:										inherit;
$project-highlight-line-height:										inherit;


/* ---- setup TYPOGRAPHY END */



/* ---- setup TABLES START */

// table
$project-table-border-radius:										$project-default-border-radius;
$project-table-margin:												$project-default-margin-bottom;

// th
$project-th-background-color:										$color-gray;
$project-th-background-color-HOVER:									$color-red;
$project-th-border-color:											$color-black;
$project-th-border-width:											1px;
$project-th-color:													$project-default-color;
$project-th-color-HOVER:											$color-white;
$project-th-font-family:											$project-default-font-family;
$project-th-font-size:												$project-default-font-size;
$project-th-line-height:											$project-default-line-height;
$project-th-padding:												10px;

// td
$project-td-background-color:										$color-light-gray;
$project-td-background-color-STRIPE:								$color-dark-gray;
$project-td-background-color-HOVER:									$color-blue;
$project-td-border-color:											$color-black;
$project-td-border-width:											$project-th-border-width;
$project-td-color:													$project-default-color;
$project-td-color-HOVER:											$color-white;
$project-td-font-family:											$project-default-font-family;
$project-td-font-size:												$project-default-font-size;
$project-td-line-height:											$project-default-line-height;
$project-td-padding:												$project-th-padding;

// table responsive
$project-table-responsive-background-color:							$color-white;
$project-table-responsive-border-color:								$color-black;
$project-table-responsive-border-width:								1px;
$project-table-responsive-margin:									$project-default-margin-bottom;


/* ---- setup TABLES END */



/* ---- setup ALERTS START */

// defaults
$project-alert-background-opacity:									0.6;
$project-alert-border-radius:										$project-default-border-radius;
$project-alert-border-width:										1px;
$project-alert-color:												$color-white;
$project-alert-font-family:											$project-default-font-family;
$project-alert-font-size:											$project-default-font-size - 2;
$project-alert-line-height:											$project-default-line-height - 2;
$project-alert-margin:												$project-default-margin-bottom;

// types

	// success
	$project-alert-background-SUCCESS:								$color-green;
	$project-alert-border-color-SUCCESS:							$color-green;
	$project-alert-color-SUCCESS:									$project-alert-color;

	// info
	$project-alert-background-INFO:									$color-blue;
	$project-alert-border-color-INFO:								$color-blue;
	$project-alert-color-INFO:										$project-alert-color;

	// warning
	$project-alert-background-WARNING:								$color-orange;
	$project-alert-border-color-WARNING:							$color-orange;
	$project-alert-color-WARNING:									$project-alert-color;

	// danger
	$project-alert-background-DANGER:								$color-red;
	$project-alert-border-color-DANGER:								$color-red;
	$project-alert-color-DANGER:									$project-alert-color;


/* ---- setup ALERTS END */



/* ---- setup BUTTONS START */

	// default

		// background color
		$project-button-background-color:								transparent;
		$project-button-background-color-HOVER:					transparent;
		$project-button-background-color-DISABLED:			$color-light-gray;

		// border color
		$project-button-border-color:										$color-green;
		$project-button-border-color-HOVER:							$color-dark-blue;
		$project-button-border-color-DISABLED:					$color-dark-gray;

		// border radius
		$project-button-border-radius:									$project-default-border-radius;
		$project-button-border-radius-SMALL:						$project-button-border-radius - 5;
		$project-button-border-radius-LARGE:						$project-button-border-radius + 5;

		// border width
		$project-button-border-width:										2px;
		$project-button-border-width-SMALL:							$project-button-border-width;
		$project-button-border-width-LARGE:							$project-button-border-width;

		// box shadow
		@mixin project-button-box-shadow()								{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-button-box-shadow();
		@mixin project-button-box-shadow-DISABLED()						{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-button-box-shadow-DISABLED();

		// color
		$project-button-color:											$color-green;
		$project-button-color-HOVER:								$color-dark-blue;
		$project-button-color-DISABLED:							$color-gray;

		// font family
		$project-button-font-family:									$project-default-font-family-1-SEMIBOLD;

		// font size
		$project-button-font-size:										$project-default-font-size - 4;
		$project-button-font-size-SMALL:							$project-button-font-size - 2;
		$project-button-font-size-LARGE:							$project-button-font-size + 2;

		// heights
		$project-button-height: 										$project-default-button-height;
		$project-button-height-SMALL: 							$project-button-height - 10;
		$project-button-height-LARGE: 							$project-button-height + 10;

		// line height
		$project-button-line-height:										$project-button-height;
		$project-button-line-height-SMALL:							$project-button-height-SMALL;
		$project-button-line-height-LARGE:							$project-button-height-LARGE;

		// opacity
		$project-button-opacity-HOVER:									$project-default-opacity-HOVER;

		// padding
		$project-button-padding:												0 35px;
		$project-button-padding-SMALL:									0 10px;
		$project-button-padding-LARGE:									0 20px;

	// button options

		// common attributes
		$project-button-option-background-opacity:						0.6;
		$project-button-option-background-opacity-HOVER:			1;
		$project-button-option-color:													$color-white;
		$project-button-option-color-HOVER:										$color-white;

		// success
		$project-button-option-background-color-SUCCESS:			$color-green;
		$project-button-option-border-color-SUCCESS:					$color-green;

		// info
		$project-button-option-background-color-INFO:					$color-blue;
		$project-button-option-border-color-INFO:							$color-blue;

		// warning
		$project-button-option-background-color-WARNING:			$color-orange;
		$project-button-option-border-color-WARNING:					$color-orange;

		// danger
		$project-button-option-background-color-DANGER:				$color-red;
		$project-button-option-border-color-DANGER:						$color-red;

		// link
		$project-button-option-color-LINK:							$color-gray;
		$project-button-option-color-LINK-HOVER:					$color-dark-gray;

	// version 1

		// background color
		$project-button-background-color-version-1:					$color-yellow;
		$project-button-background-color-version-1-HOVER:			$color-orange;
		$project-button-background-color-version-1-DISABLED:		$color-white;

		// border color
		$project-button-border-color-version-1:						$color-green;
		$project-button-border-color-version-1-HOVER:				$color-dark-green;
		$project-button-border-color-version-1-DISABLED:			$color-light-gray;

		// border radius
		$project-button-border-radius-version-1:					$project-default-border-radius;
		$project-button-border-radius-version-1-SMALL:				$project-button-border-radius-version-1 - 5;
		$project-button-border-radius-version-1-LARGE:				$project-button-border-radius-version-1 + 5;

		// border width
		$project-button-border-width-version-1:						1px;
		$project-button-border-width-version-1-SMALL:				$project-button-border-width-version-1;
		$project-button-border-width-version-1-LARGE:				$project-button-border-width-version-1;

		// box shadow
		@mixin project-button-box-shadow-version-1()				{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-button-box-shadow-version-1();
		@mixin project-button-box-shadow-version-1-DISABLED()		{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-button-box-shadow-version-1-DISABLED();

		// color
		$project-button-color-version-1:							$color-green;
		$project-button-color-version-1-HOVER:						$color-dark-green;
		$project-button-color-version-1-DISABLED:					$color-dark-gray;

		// font family
		$project-button-font-family-version-1:						$project-default-font-family;

		// font size
		$project-button-font-size-version-1:						$project-default-font-size;
		$project-button-font-size-version-1-SMALL:					$project-button-font-size-version-1 - 2;
		$project-button-font-size-version-1-LARGE:					$project-button-font-size-version-1 + 2;

		// heights
		$project-button-height-version-1: 							$project-default-button-height;
		$project-button-height-version-1-SMALL: 					$project-button-height-version-1 - 10;
		$project-button-height-version-1-LARGE: 					$project-button-height-version-1 + 10;

		// line height
		$project-button-line-height-version-1:						$project-button-height-version-1;
		$project-button-line-height-version-1-SMALL:				$project-button-height-version-1-SMALL;
		$project-button-line-height-version-1-LARGE:				$project-button-height-version-1-LARGE;

		// opacity
		$project-button-opacity-version-1-HOVER:					$project-default-opacity-HOVER;

		// padding
		$project-button-padding-version-1:							0 15px;
		$project-button-padding-version-1-SMALL:					0 10px;
		$project-button-padding-version-1-LARGE:					0 20px;


/* ---- setup BUTTONS END */



/* ---- setup FORM ELEMENTS START */

/* form group */

	// margin
	$project-form-group-margin:										$project-default-margin-bottom;


/* form controls (input, select, textarea) */

	// background color
	$project-form-control-background-color:							$color-white;
	$project-form-control-background-color-FOCUS:				$color-white;
	$project-form-control-background-color-ERROR:				$color-white;
	$project-form-control-background-color-DISABLED:		$color-light-gray;

	// border color
	$project-form-control-border-color:								$color-gray;
	$project-form-control-border-color-FOCUS:					none;
	$project-form-control-border-color-ERROR:					$color-red;
	$project-form-control-border-color-DISABLED:			$color-dark-gray;

	// border radius
	$project-form-control-border-radius:									$project-default-border-radius;
	$project-form-control-border-radius-SMALL:						$project-form-control-border-radius - 5;
	$project-form-control-border-radius-LARGE:						$project-form-control-border-radius + 5;

	// border width
	$project-form-control-border-width:									0px;
	$project-form-control-border-width-SMALL:						$project-form-control-border-width;
	$project-form-control-border-width-LARGE:						$project-form-control-border-width;

	// box shadow
	@mixin project-form-control-box-shadow()						{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow();
	@mixin project-form-control-box-shadow-FOCUS()					{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow-FOCUS();
	@mixin project-form-control-box-shadow-ERROR()					{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow-ERROR();
	@mixin project-form-control-box-shadow-DISABLED()				{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow-DISABLED();

	// color
	$project-form-control-color:											$color-black;
	$project-form-control-color-FOCUS:								$color-black;
	$project-form-control-color-ERROR:								$color-red;
	$project-form-control-color-DISABLED:							$color-dark-gray;

	$project-form-control-color-placeholder:								$color-black;
	$project-form-control-color-placeholder-FOCUS:					$color-black;
	$project-form-control-color-placeholder-ERROR:					$color-red;
	$project-form-control-color-placeholder-DISABLED:				$color-dark-gray;

	// font family
	$project-form-control-font-family:								$project-default-font-family;

	// font size
	$project-form-control-font-size:								$project-default-font-size;
	$project-form-control-font-size-SMALL:							$project-form-control-font-size - 2;
	$project-form-control-font-size-LARGE:							$project-form-control-font-size + 2;

	// height
	$project-form-control-height: 									$project-default-button-height;
	$project-form-control-height-SMALL: 							$project-button-height-SMALL;
	$project-form-control-height-LARGE: 							$project-button-height-LARGE;

	// line height
	$project-form-control-line-height:								$project-form-control-height;
	$project-form-control-line-height-SMALL:						$project-form-control-height-SMALL;
	$project-form-control-line-height-LARGE:						$project-form-control-height-LARGE;

	// paddings
	$project-form-control-padding:									10px 15px;
	$project-form-control-padding-SMALL:						5px 10px;
	$project-form-control-padding-LARGE:						15px 20px;

	// version 1

		// background color
		$project-form-control-background-color-version-1:			$color-yellow;
		$project-form-control-background-color-version-1-FOCUS:		$color-orange;
		$project-form-control-background-color-version-1-ERROR:		$color-yellow;
		$project-form-control-background-color-version-1-DISABLED:	$color-white;

		// border color
		$project-form-control-border-color-version-1:				$color-green;
		$project-form-control-border-color-version-1-FOCUS:			$color-dark-green;
		$project-form-control-border-color-version-1-ERROR:			$color-dark-red;
		$project-form-control-border-color-version-1-DISABLED:		$color-light-gray;

		// border radius
		$project-form-control-border-radius-version-1:				$project-default-border-radius;
		$project-form-control-border-radius-version-1-SMALL:		$project-form-control-border-radius-version-1 - 5;
		$project-form-control-border-radius-version-1-LARGE:		$project-form-control-border-radius-version-1 + 5;

		// border width
		$project-form-control-border-width-version-1:				1px;
		$project-form-control-border-width-version-1-SMALL:			$project-form-control-border-width-version-1;
		$project-form-control-border-width-version-1-LARGE:			$project-form-control-border-width-version-1;

		// box shadow
		@mixin project-form-control-box-shadow-version-1()			{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow-version-1();
		@mixin project-form-control-box-shadow-version-1-FOCUS()	{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow-version-1-FOCUS();
		@mixin project-form-control-box-shadow-version-1-ERROR()	{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow-version-1-ERROR();
		@mixin project-form-control-box-shadow-version-1-DISABLED()	{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-form-control-box-shadow-version-1-DISABLED();

		// color
		$project-form-control-color-version-1:						$color-dark-green;
		$project-form-control-color-version-1-FOCUS:				$color-green;
		$project-form-control-color-version-1-ERROR:				$color-dark-red;
		$project-form-control-color-version-1-DISABLED:				$color-dark-gray;

		$project-form-control-color-version-1-placeholder:			$color-dark-green;
		$project-form-control-color-version-1-placeholder-FOCUS:	$color-green;
		$project-form-control-color-version-1-placeholder-ERROR:	$color-dark-red;
		$project-form-control-color-version-1-placeholder-DISABLED:	$color-dark-gray;

		// font family
		$project-form-control-font-family-version-1:				$project-default-font-family;

		// font size
		$project-form-control-font-size-version-1:					$project-default-font-size;
		$project-form-control-font-size-version-1-SMALL:			$project-form-control-font-size-version-1 - 2;
		$project-form-control-font-size-version-1-LARGE:			$project-form-control-font-size-version-1 + 2;

		// height
		$project-form-control-height-version-1: 					$project-button-height-version-1;
		$project-form-control-height-version-1-SMALL: 				$project-button-height-version-1-SMALL;
		$project-form-control-height-version-1-LARGE: 				$project-button-height-version-1-LARGE;

		// line height
		$project-form-control-line-height-version-1:				$project-form-control-height-version-1;
		$project-form-control-line-height-version-1-SMALL:			$project-form-control-height-version-1-SMALL;
		$project-form-control-line-height-version-1-LARGE:			$project-form-control-height-version-1-LARGE;

		// paddings
		$project-form-control-padding-version-1:					10px 15px;
		$project-form-control-padding-version-1-SMALL:				 5px 10px;
		$project-form-control-padding-version-1-LARGE:				15px 20px;

/* select skin */

	// select skin
	$project-select-skin-background-image:							'../img/icons/select-skin-arrow-default.svg';
	$project-select-skin-background-size-x:							10px;
	$project-select-skin-background-size-y:							auto;
	$project-select-skin-background-size-x-SMALL:					8px;
	$project-select-skin-background-size-y-SMALL:					auto;
	$project-select-skin-background-size-x-LARGE:					15px;
	$project-select-skin-background-size-y-LARGE:					auto;
	$project-select-skin-background-position:						15px;
	$project-select-skin-background-position-SMALL:					10px;
	$project-select-skin-background-position-LARGE:					20px;

		// version 1
		$project-select-skin-background-image-version-1:			'../img/icons/select-skin-arrow-version-1.svg';
		$project-select-skin-background-size-x-version-1:			14px;
		$project-select-skin-background-size-y-version-1:			auto;
		$project-select-skin-background-size-x-version-1-SMALL:		12px;
		$project-select-skin-background-size-y-version-1-SMALL:		auto;
		$project-select-skin-background-size-x-version-1-LARGE:		16px;
		$project-select-skin-background-size-y-version-1-LARGE:		auto;
		$project-select-skin-background-position-version-1:			22px;
		$project-select-skin-background-position-version-1-SMALL:	20px;
		$project-select-skin-background-position-version-1-LARGE:	26px;


/* checkbox skin + radio button skin */

	// background color
	$project-checkradio-background-color:							$color-white;
	$project-checkradio-background-color-FOCUS:						$color-white;
	$project-checkradio-background-color-CHECKED:					$color-white;
	$project-checkradio-background-color-ERROR:						$color-white;
	$project-checkradio-background-color-DISABLED:					$color-light-gray;

	// border color
	$project-checkradio-border-color:								$color-gray;
	$project-checkradio-border-color-FOCUS:							$color-black;
	$project-checkradio-border-color-CHECKED:						$color-black;
	$project-checkradio-border-color-ERROR:							$color-red;
	$project-checkradio-border-color-DISABLED:						$color-dark-gray;

	// border radius
	$project-checkradio-border-radius:								$project-default-border-radius / 2;

	// border width
	$project-checkradio-border-width:								1px;

	// distance
	$project-checkradio-distance:									10px;

	// height
	$project-checkradio-height:										$project-default-button-height / 2;

	// inside color
	$project-checkradio-inside-color:								$color-gray;
	$project-checkradio-inside-color-FOCUS:							$color-black;
	$project-checkradio-inside-color-CHECKED:						$color-black;
	$project-checkradio-inside-color-ERROR:							$color-red;
	$project-checkradio-inside-color-DISABLED:						$color-dark-gray;

	// width
	$project-checkradio-width:										$project-checkradio-height;

	// checkbox icon
	$project-checkradio-checkbox-icon:								'\2714'; // '✔'

	// checkbox size
	$project-checkradio-checkbox-size:								$project-checkradio-height - 4;

	// radio size
	$project-checkradio-radio-size:									$project-checkradio-height - 10;

	// version 1

		// background color
		$project-checkradio-background-color-version-1:				$color-yellow;
		$project-checkradio-background-color-version-1-FOCUS:		$color-orange;
		$project-checkradio-background-color-version-1-CHECKED:		$color-yellow;
		$project-checkradio-background-color-version-1-ERROR:		$color-yellow;
		$project-checkradio-background-color-version-1-DISABLED:	$color-white;

		// border color
		$project-checkradio-border-color-version-1:					$color-green;
		$project-checkradio-border-color-version-1-FOCUS:			$color-dark-green;
		$project-checkradio-border-color-version-1-CHECKED:			$color-dark-green;
		$project-checkradio-border-color-version-1-ERROR:			$color-dark-red;
		$project-checkradio-border-color-version-1-DISABLED:		$color-light-gray;

		// border radius
		$project-checkradio-border-radius-version-1:				$project-default-border-radius / 2 + 2;

		// border width
		$project-checkradio-border-width-version-1:					2px;

		// distance
		$project-checkradio-distance-version-1:						15px;

		// height
		$project-checkradio-height-version-1:						$project-default-button-height / 2 + 2;

		// inside color
		$project-checkradio-inside-color-version-1:					$color-dark-green;
		$project-checkradio-inside-color-version-1-FOCUS:			$color-green;
		$project-checkradio-inside-color-version-1-CHECKED:			$color-green;
		$project-checkradio-inside-color-version-1-ERROR:			$color-dark-red;
		$project-checkradio-inside-color-version-1-DISABLED:		$color-dark-gray;

		// width
		$project-checkradio-width-version-1:						$project-checkradio-height-version-1;

		// checkbox icon
		$project-checkradio-checkbox-icon-version-1:				'\2661'; // '♡'

		// checkbox size
		$project-checkradio-checkbox-size-version-1:				$project-checkradio-height-version-1 - 4;

		// radio size
		$project-checkradio-radio-size-version-1:					$project-checkradio-height-version-1 - 10;


/* labels */

	/* for form controls */

		// color
		$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-dark-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;

		// version 1

			// color
			$project-label-color-version-1:							$color-green;
			$project-label-color-version-1-ERROR:					$color-dark-red;
			$project-label-color-version-1-DISABLED:				$color-light-gray;

			// font family
			$project-label-font-family-version-1:					$project-default-font-family;

			// font size
			$project-label-font-size-version-1:						$project-default-font-size + 2;
			$project-label-font-size-version-1-SMALL:				$project-label-font-size-version-1 - 2;
			$project-label-font-size-version-1-LARGE:				$project-label-font-size-version-1 + 2;

			// height
			$project-label-height-version-1:		 				$project-form-control-height-version-1;
			$project-label-height-version-1-SMALL: 					$project-form-control-height-version-1-SMALL;
			$project-label-height-version-1-LARGE: 					$project-form-control-height-version-1-LARGE;

			// line height
			$project-label-line-height-version-1:					$project-label-height-version-1;
			$project-label-line-height-version-1-SMALL:				$project-label-height-version-1-SMALL;
			$project-label-line-height-version-1-LARGE:				$project-label-height-version-1-LARGE;


	/* for checkbox + radio button */

		// color
		$project-label-checkradio-color:							$project-default-color;
		$project-label-checkradio-color-ERROR:						$color-red;
		$project-label-checkradio-color-DISABLED:					$color-gray;

		// font family
		$project-label-checkradio-font-family:						$project-default-font-family;

		// font size
		$project-label-checkradio-font-size:						$project-default-font-size - 2;
		$project-label-checkradio-font-size-SMALL:					$project-label-checkradio-font-size - 2;
		$project-label-checkradio-font-size-LARGE:					$project-label-checkradio-font-size + 2;

		// line height
		$project-label-checkradio-line-height:						$project-label-checkradio-font-size + $project-default-line-height-diff;
		$project-label-checkradio-line-height-SMALL:				$project-label-checkradio-font-size-SMALL + $project-default-line-height-diff;
		$project-label-checkradio-line-height-LARGE:				$project-label-checkradio-font-size-LARGE + $project-default-line-height-diff;

		// version 1

			// color
			$project-label-checkradio-color-version-1:				$project-label-color-version-1;
			$project-label-checkradio-color-version-1-ERROR:		$project-label-color-version-1-ERROR;
			$project-label-checkradio-color-version-1-DISABLED:		$project-label-color-version-1-DISABLED;

			// font family
			$project-label-checkradio-font-family-version-1:		$project-default-font-family;

			// font size
			$project-label-checkradio-font-size-version-1:			$project-default-font-size + 2;
			$project-label-checkradio-font-size-version-1-SMALL:	$project-label-checkradio-font-size-version-1 - 2;
			$project-label-checkradio-font-size-version-1-LARGE:	$project-label-checkradio-font-size-version-1 + 2;

			// line height
			$project-label-checkradio-line-height-version-1:		$project-label-checkradio-font-size-version-1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-version-1-SMALL:	$project-label-checkradio-font-size-version-1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-version-1-LARGE:	$project-label-checkradio-font-size-version-1-LARGE + $project-default-line-height-diff;

/* help block */

	// help block
	$project-help-block-color: 										$project-default-color;
	$project-help-block-color-ERROR:								$color-red;
	$project-help-block-color-DISABLED:								$color-gray;
	$project-help-block-font-family: 								$project-default-font-family;
	$project-help-block-font-size: 									$project-default-font-size - 1;
	$project-help-block-line-height: 								$project-help-block-font-size + $project-default-line-height-diff;
	$project-help-block-margin: 									5px 0 0 0;

		// version 1
		$project-help-block-color-version-1: 						$color-gray;
		$project-help-block-color-version-1-ERROR:					$color-dark-red;
		$project-help-block-color-version-1-DISABLED:				$color-dark-gray;
		$project-help-block-font-family-version-1: 					$project-default-font-family;
		$project-help-block-font-size-version-1: 					$project-default-font-size;
		$project-help-block-line-height-version-1: 					$project-help-block-font-size-version-1 + $project-default-line-height-diff;
		$project-help-block-margin-version-1: 						10px 0 0 0;



/* ---- setup FORM ELEMENTS END */



/* ---- setup PAGINATION START */

// background color
$project-pagination-background-color:								$color-white;
$project-pagination-background-color-HOVER:							$color-light-gray;
$project-pagination-background-color-ACTIVE:						$color-red;
$project-pagination-background-color-ACTIVE-HOVER:					$color-dark-red;
$project-pagination-background-color-DISABLED:						$color-light-gray;
$project-pagination-background-color-PAGER:							$color-gray;
$project-pagination-background-color-PAGER-HOVER:					$color-light-gray;

// border color
$project-pagination-border-color:									$color-blue;
$project-pagination-border-color-HOVER:								$color-black;
$project-pagination-border-color-ACTIVE:							$color-dark-red;
$project-pagination-border-color-ACTIVE-HOVER:						$color-dark-red;
$project-pagination-border-color-DISABLED:							$color-gray;
$project-pagination-border-color-PAGER:								$color-black;
$project-pagination-border-color-PAGER-HOVER:						$color-gray;

// border radius
$project-pagination-border-radius:									$project-default-border-radius;
$project-pagination-border-radius-SMALL:							$project-pagination-border-radius - 5;
$project-pagination-border-radius-LARGE:							$project-pagination-border-radius + 5;

// border width
$project-pagination-border-width:									1px;
$project-pagination-border-width-SMALL:								$project-pagination-border-width;
$project-pagination-border-width-LARGE:								$project-pagination-border-width;

// color
$project-pagination-color:											$color-blue;
$project-pagination-color-HOVER:									$color-black;
$project-pagination-color-ACTIVE:									$color-white;
$project-pagination-color-ACTIVE-HOVER:								$color-white;
$project-pagination-color-DISABLED:									$color-gray;
$project-pagination-color-PAGER:									$color-white;
$project-pagination-color-PAGER-HOVER:								$color-black;

// font family
$project-pagination-font-family:									$project-default-font-family;

// font size
$project-pagination-font-size:										$project-default-font-size;
$project-pagination-font-size-SMALL:								$project-pagination-font-size - 2;
$project-pagination-font-size-LARGE:								$project-pagination-font-size + 2;

// line height
$project-pagination-line-height:									$project-button-height;
$project-pagination-line-height-SMALL:								$project-button-height-SMALL;
$project-pagination-line-height-LARGE:								$project-button-height-LARGE;

// margin
$project-pagination-margin:											$project-default-margin-bottom;

// padding
$project-pagination-padding:										0px 15px;
$project-pagination-padding-SMALL:									0px 10px;
$project-pagination-padding-LARGE:									0px 20px;

// opacity
$project-pagination-opacity-HOVER:									1;


/* ---- setup PAGINATION END */



/* ---- setup MARGINS START */

// vertical margin
$project-vertical-margin-MOBILE:									25px;
$project-vertical-margin-TABLET:									40px;
$project-vertical-margin-LAPTOP:									55px;
$project-vertical-margin-HD:										70px;

// vertical margin small
$project-vertical-margin-small-MOBILE:								25px;
$project-vertical-margin-small-TABLET:								35px;
$project-vertical-margin-small-LAPTOP:								45px;
$project-vertical-margin-small-HD:									55px;

// vertical margin large
$project-vertical-margin-large-MOBILE:								25px;
$project-vertical-margin-large-TABLET:								45px;
$project-vertical-margin-large-LAPTOP:								65px;
$project-vertical-margin-large-HD:									85px;


/* ---- setup MARGINS END */



/* ---- setup PADDINGS START */

// vertical padding
$project-vertical-padding-MOBILE:									25px;
$project-vertical-padding-TABLET:									40px;
$project-vertical-padding-LAPTOP:									55px;
$project-vertical-padding-HD:										70px;

// vertical padding small
$project-vertical-padding-small-MOBILE:								25px;
$project-vertical-padding-small-TABLET:								35px;
$project-vertical-padding-small-LAPTOP:								45px;
$project-vertical-padding-small-HD:									55px;

// vertical padding large
$project-vertical-padding-large-MOBILE:								25px;
$project-vertical-padding-large-TABLET:								45px;
$project-vertical-padding-large-LAPTOP:								65px;
$project-vertical-padding-large-HD:									85px;


/* ---- setup PADDINGS END */



/* ---- setup EMBED RESPONSIVE LAYER START */

$project-embed-responsive-layer-background-color:					$color-blue;
$project-embed-responsive-layer-background-color-HOVER:				$color-blue;
$project-embed-responsive-layer-background-opacity:					0.6;
$project-embed-responsive-layer-background-opacity-HOVER:			0.4;
$project-embed-responsive-layer-color:								$color-white;
$project-embed-responsive-layer-font-family:						$project-default-font-family;
$project-embed-responsive-layer-font-size:							$project-default-font-size;
$project-embed-responsive-layer-opacity:							0;
$project-embed-responsive-layer-opacity-HOVER:						1;


/* ---- setup EMBED RESPONSIVE LAYER END */



/* ---- setup EMBED RESPONSIVE LAYER YOUTUBE START */

$project-embed-responsive-layer-youtube-background-color:			$color-blue;
$project-embed-responsive-layer-youtube-background-color-HOVER:		$color-blue;
$project-embed-responsive-layer-youtube-background-opacity:			0.6;
$project-embed-responsive-layer-youtube-background-opacity-HOVER:	0.4;
$project-embed-responsive-layer-youtube-color:						$color-white;
$project-embed-responsive-layer-youtube-font-family:				$project-default-font-family;
$project-embed-responsive-layer-youtube-font-size:					$project-default-font-size;
$project-embed-responsive-layer-youtube-opacity:					0;
$project-embed-responsive-layer-youtube-opacity-HOVER:				1;


/* ---- setup EMBED RESPONSIVE LAYER YOUTUBE END */



/* ---- setup cookie-bar START */

// close
$project-cookie-bar-close-background-color:							$color-green;
$project-cookie-bar-close-background-color-HOVER:					$color-dark-green;
$project-cookie-bar-close-border-color:								$color-dark-green;
$project-cookie-bar-close-border-color-HOVER:						$color-dark-green;
$project-cookie-bar-close-border-radius:							$project-default-border-radius;
$project-cookie-bar-close-border-width:								1px;
$project-cookie-bar-close-color:									$color-orange;
$project-cookie-bar-close-color-HOVER:								$color-yellow;
$project-cookie-bar-close-font-family:								$project-default-font-family;
$project-cookie-bar-close-font-size:								$project-default-font-size;
$project-cookie-bar-close-height:									30px;
$project-cookie-bar-close-width:									$project-cookie-bar-close-height;

// layer
$project-cookie-bar-layer-background-color:							$color-green;
@mixin project-cookie-bar-layer-box-shadow()						{ @include css3-box-shadow(0, 0, 0, rgba($color-black, 0), ''); } // USE THIS: @include project-cookie-bar-layer-box-shadow();
$project-cookie-bar-layer-opacity:									0.8;
$project-cookie-bar-layer-padding:									20px;

// link
$project-cookie-bar-link-color:										$color-orange;
$project-cookie-bar-link-font-family:								$project-default-font-family;

// text
$project-cookie-bar-text-color:										$color-white;
$project-cookie-bar-text-font-family:								$project-default-font-family;
$project-cookie-bar-text-font-size:									$project-default-font-size;
$project-cookie-bar-text-line-height:								$project-default-line-height;


/* ---- setup cookie-bar END */



/* ---- setup fake position START */


$project-fake-position-top:											-50px;


/* ---- setup fake position END */



/* variables END */
