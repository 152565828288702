/*** Project loader ***/

/* loader START */

// basics
.loader {

	// layers
	&:before,
	&:after {
		@include css3-prefix(transform, translate3d(0, 0, 100px));
		background-color: $project-loader-layer-color;
		bottom: 0;
		content: '';
		display: block;
		position: fixed;
		top: 0;
		z-index: 100;

		// loaded
		.loaded & {
			@include css3-prefix(transition, all $project-loader-layer-speed $project-loader-layer-delay ease-out);
			visibility: hidden;
		}

		// if no js
		.no-js & {
			display: none;
		}
	}

	&:before {
		left: 0;
		right: 50%;

		// loaded
		.loaded & {
			@include css3-prefix(transform, translateX(-100%));
		}
	}

	&:after {
		left: 50%;
		right: 0;

		// loaded
		.loaded & {
			@include css3-prefix(transform, translateX(100%));
		}
	}

	// animations
	[class^="loader-"],
	[class*=" loader-"] {
		@include css3-opacity(1);
		@include css3-prefix(transform, translate3d(0, 0, 101px));
		display: block;
		left: 50%;
		height: $project-loader-item-size * 5;
		line-height: $project-loader-item-size * 5;
		margin-left: -( $project-loader-item-size * 10 ) / 2;
		margin-top: -( $project-loader-item-size * 5 ) / 2;
		position: fixed;
		text-align: center;
		top: 50%;
		vertical-align: top;
		width: $project-loader-item-size * 10;
		z-index: 101;

		// loaded
		.loaded & {
			@include css3-prefix(transition, all $project-loader-layer-speed 0s ease-out);
			@include css3-opacity(0);
			visibility: hidden;
		}

		// if no js
		.no-js & {
			display: none;
		}
	}
}

// Components
/* based on https://connoratherton.com/loadersloaders */
@import "loader/logo";                            // HTML: <div class="loader-logo"><div></div><div></div></div>
//@import "loader/ball-pulse";                    // HTML: <div class="loader-ball-pulse"><div></div><div></div><div></div></div>
//@import "loader/ball-pulse-sync";               // HTML: <div class="loader-ball-pulse-sync"><div></div><div></div><div></div></div>
//@import "loader/ball-scale";                    // HTML: <div class="loader-ball-scale"><div></div></div>
//@import "loader/ball-rotate";                   // HTML: <div class="loader-ball-rotate"><div></div></div>
//@import "loader/ball-clip-rotate";              // HTML: <div class="loader-ball-clip-rotate"><div></div></div>
//@import "loader/ball-clip-rotate-pulse";        // HTML: <div class="loader-ball-clip-rotate-pulse"><div></div><div></div></div>
//@import "loader/ball-clip-rotate-multiple";     // HTML: <div class="loader-ball-clip-rotate-multiple"><div></div><div></div></div>
//@import "loader/ball-clip-rotate-multiple2";    // HTML: <div class="loader-ball-clip-rotate-multiple2"><div></div><div></div><div></div></div>
//@import "loader/ball-scale-ripple";             // HTML: <div class="loader-ball-scale-ripple"><div></div></div>
//@import "loader/ball-scale-ripple-multiple";    // HTML: <div class="loader-ball-scale-ripple-multiple"><div></div><div></div><div></div></div>
//@import "loader/ball-beat";                     // HTML: <div class="loader-ball-beat"><div></div><div></div><div></div></div>
//@import "loader/ball-scale-multiple";           // HTML: <div class="loader-ball-scale-multiple"><div></div><div></div><div></div></div>
//@import "loader/ball-triangle-path";            // HTML: <div class="loader-ball-triangle-path"><div></div><div></div><div></div></div>
//@import "loader/ball-pulse-rise";               // HTML: <div class="loader-ball-pulse-rise"><div></div><div></div><div></div><div></div><div></div></div>
//@import "loader/ball-grid-beat";                // HTML: <div class="loader-ball-grid-beat"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
//@import "loader/ball-grid-pulse";               // HTML: <div class="loader-ball-grid-pulse"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
//@import "loader/ball-spin-fade-loader";         // HTML: <div class="loader-ball-spin-fade-loader"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
//@import "loader/ball-spin-loader";              // HTML: <div class="loader-ball-spin-loader"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
//@import "loader/ball-zig-zag";                  // HTML: <div class="loader-ball-zig-zag"><div><div></div><div></div></div></div>
//@import "loader/ball-zig-zag-deflect";          // HTML: <div class="loader-ball-zig-zag-deflect"><div><div></div><div></div></div></div>
//@import "loader/line-scale";                    // HTML: <div class="loader-line-scale"><div></div><div></div><div></div><div></div><div></div></div>
//@import "loader/line-scale-party";              // HTML: <div class="loader-line-scale-party"><div></div><div></div><div></div><div></div></div>
//@import "loader/line-scale-pulse-out";          // HTML: <div class="loader-line-scale-pulse-out"><div></div><div></div><div></div><div></div><div></div></div>
//@import "loader/line-scale-pulse-out-rapid";    // HTML: <div class="loader-line-scale-pulse-out-rapid"><div></div><div></div><div></div><div></div><div></div></div>
//@import "loader/line-spin-fade";                // HTML: <div class="loader-line-spin-fade"><div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
//@import "loader/triangle-skew-spin";            // HTML: <div class="loader-triangle-skew-spin"><div></div></div>
//@import "loader/square-spin";                   // HTML: <div class="loader-square-spin"><div></div></div>
//@import "loader/pacman";                        // HTML: <div class="loader-pacman"><div></div><div></div><div></div><div></div></div>
//@import "loader/cube-transition";               // HTML: <div class="loader-cube-transition"><div><div></div><div></div></div></div>
//@import "loader/semi-circle-spin";              // HTML: <div class="loader-semi-circle-spin"><div><div></div></div></div>
